<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				wyneProduct:{},
				wyneHero:{},
				filter:{brand:[],body:[],scale:[],country:[],order:'asc',type:"",varietal:""},
				wyneType:{},
				brandType:{},
				topVarietal:{},
				bodySize:{},
				dryScale:{},
				mrCountry:{},
				mrRegion:{},
				Pagination:[],
				default_limit: 8,
		        limit_by: 8,		        
		        limit_brand: 8,		        
		        limit_region: 8,		        
				containerId:'',
				loadingProduct:true,
            }
		},
		async mounted() {
			await this.ready();
			this.filterReload()
            this.$set(this.$root, 'page', this);
            if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			$(document).ready(() => { AOS.init() })
            this.apiGetProduct()
            this.apiGetImageHeader()
            this.apiGetWineType()
            this.apiGetTopVarietal()
            this.apiGetWineBrand()
            this.apiGetBodySize()
            this.apiGetDryScale()
            this.apiGetCountry()
            this.filter.order="asc"
		},
		watch:{
			'$route.query'(v){
				this.apiGetProduct()
				this.filterReload()
			},
			'$route.query.search'(v){
				this.doFilter()
			},
			'filter.type'(v){
				// if(!v) return
				// let query = Gen.clone(this.$route.query)
				// if(query.search) query.search = ""
				// this.$set(this, 'filter', query)
				this.doFilter()
			},
			'filter.varietal'(v){
				this.doFilter()
			},
			'filter.order'(v){
				this.doFilter()
			},
		},
        methods: {
        	simple_toggle(default_limit, filters_length) {
	            this.limit_by = (this.limit_by === default_limit) ? filters_length : default_limit;
	        },
        	simple_brand(default_limit, filters_length) {
	            this.limit_brand = (this.limit_brand === default_limit) ? filters_length : default_limit;
	        },
        	simple_region(default_limit, filters_length) {
	            this.limit_region = (this.limit_region === default_limit) ? filters_length : default_limit;
	        },
	        filterReload(){
	        	let query = Gen.clone(this.$route.query)
	        	query.brand = query.brand ? query.brand.split(",") : []
	        	query.body = query.body ? query.body.split(",") : []
	        	query.scale = query.scale ? query.scale.split(",") : []
	        	query.country = query.country ? query.country.split(",") : []
				query.region = query.region ? query.region.split(",") : []
				this.$set(this, 'filter', query)
	        },
	        doCheck(name, val){
	        	let exist = this.filter[name].indexOf(val.toString())
	        	if(exist>-1){ 
	        		this.filter[name].splice(exist, 1)
	        	}else{
	        		this.filter[name].push(val.toString())
	        	}

	        	this.doFilter()
	        },
	        doFilter(){
	        	let query = Object.assign(Gen.clone(this.$route.query), Gen.clone(this.filter))
	        	query.page = 1   
	        	if(query.brand) query.brand = query.brand.join(",")
	        	if(query.body) query.body = query.body.join(",")
	        	if(query.scale) query.scale = query.scale.join(",")
	        	if(query.country) query.country = query.country.join(",")
	        	if(query.region) query.region = query.region.join(",")
	        	setTimeout(()=>{
					this.$router.push({path: this.$route.path, query: query })
				}, 50)
	        },
        	apiGetImageHeader(){
        		Gen.apirest("/wyne-hero",{},(err,resp)=>{
                    if(err) console.log(err)
					this.wyneHero = resp.data
					setTimeout(() => { SEMICOLON.documentOnLoad.init() }, 200);
                })
        	},
        	apiGetProduct(){
        		this.loadingProduct=true
        		Gen.apirest("/wyne-product",Gen.clone(this.$route.query),(err,resp)=>{
        			this.loadingProduct=false
                    if(err) console.log(err)
                    this.wyneProduct = resp.data.data
                	this.Pagination = resp.data
                	setTimeout(()=>{
						this.$redrawVueMasonry.apply()
					},500)
                })
        	},
        	apiGetWineType(){
                Gen.apirest("/wyne-type",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.wyneType = resp.data
                })
            },
            apiGetTopVarietal(){
                Gen.apirest("/top-varietals",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.topVarietal = resp.data
                })
            },
        	apiGetWineBrand(){
                Gen.apirest("/brand-type",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.brandType = resp.data
                })
            },
        	apiGetBodySize(){
                Gen.apirest("/body-size",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.bodySize = resp.data
                })
            },
        	apiGetDryScale(){
                Gen.apirest("/dry-scale",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.dryScale = resp.data
                })
            },
        	apiGetCountry(){
                Gen.apirest("/country-all",{},(err,resp)=>{
                    if(err) console.log(err)
                    this.mrCountry = resp.data
                    this.mrRegion = resp.region
                })
            },
            onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			},
			clickFilter(){
				$("#filterMobile").toggleClass("filter-show");
				$("body").toggleClass("show-menu");
				$(".filter-overlay").toggle();
			}
        },
	};
</script>
<template>
	<div>
	    <section id="slider" class="slider-element boxed-slider notoppadding">
	        <div class="fslider" data-touch="false" data-easing="easeInQuad">
	            <div class="flexslider">
	                <div class="slider-wrap">
						<div v-if="!isMobile" class="slide" :data-thumb="uploader(wyneHero.ash_image_desktop)">
							<img :src="uploader(wyneHero.ash_image_desktop)" :alt="wyneHero['ash_title_'+hl]" :title="wyneHero['ash_title_'+hl]">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+wyneHero.ash_title_color+';font-family:'+wyneHero.ash_title_font_type+';font-size:'+wyneHero.ash_title_size+'px;'">{{ wyneHero['ash_title_'+hl] }}</h2>
								<p :style="'font-size:'+wyneHero.ash_sub_title_size+'px;color:'+wyneHero.ash_sub_title_color+';font-family:'+wyneHero.ash_sub_title_font_type+';'" v-if="wyneHero.ash_sub_title_en">{{ wyneHero['ash_sub_title_'+hl] }}</p>							
							</div>
						</div>
						<div v-else class="slide" :data-thumb="uploader(wyneHero.ash_image_mobile)">
							<img :src="uploader(wyneHero.ash_image_mobile)" :alt="wyneHero['ash_title_'+hl]" :title="wyneHero['ash_title_'+hl]">
							<div class="flex-caption slider-caption-bg">
								<h2 :style="'color:'+wyneHero.ash_title_color+';font-family:'+wyneHero.ash_title_font_type+';'">{{ wyneHero['ash_title_'+hl] }}</h2>
							</div>
						</div>
					</div>
	            </div>
	        </div>
	    </section>
	    <section id="content" style="background-color: #000; overflow: visible">
	        <div class="content-wrap pt-0 pb-0">
	            <div id="section-about" class="section page-section bg_white  nobottommargin notopmargin clearfix">
	                <div class="container clearfix">
	                    <div class="row justify-content-around">

	                        <div class="col-lg-7 col-md-6">
	                            <ol class="breadcrumb">
	                                <li class="breadcrumb-item"><router-link :to="{name:'Home'}">{{ web.mn_home }}</router-link></li>
	                                <!-- <li class="breadcrumb-item"><a href="#">Wine</a></li> -->
	                                <li class="breadcrumb-item active" aria-current="page">Wine</li>
	                            </ol>
	                        </div>
	                        <div class="col-md-3 d-none d-md-block">
	                            <div class="txt_sort_by">
	                                <p>{{web.lbl_sort_by}}</p>
	                            </div>
	                        </div>
	                        <div class="col-lg-2 col-md-3 pl-md-0 d-none d-md-block">
	                            <div class="form-group select_custom_dark">
	                                <select v-model="filter.order" id="template-contactform-service" @change="doFilter" name="order" class="sm-form-control frm_custom_filter valid">
	                                    <option value="asc">A-Z</option>
	                                    <option value="desc">Z-A</option>
	                                    <option value="favorite">{{web.lbl_favorite}}</option>
	                                </select>
	                            </div>
	                        </div>
	                    </div>
	                    <div class="row">
	                        <div class="col-md-4 col-lg-3 d-none d-md-block">
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_type}} <a class="expand" data-toggle="collapse" href="#show_cat" role="button"
	                                    aria-expanded="false" aria-controls="show_cat"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="show_cat">
	                                	<li>
	                                        <div class="col_10">
	                                            <div>
	                                                <input id="'radio-0'" name="type" class="radio-style" 
													value="" checked="" v-model="filter.type" type="radio">
	                                                <label for="'radio-0'" class="radio-style-3-label">All</label>
	                                            </div>
	                                        </div>
	                                    </li>
                                    	<div class="clearfix"></div>
	                                	<element v-for="(t,kt) in wyneType"  :key="kt">
	                                		<li>
		                                        <div class="col_10">
		                                            <div>
		                                                <input :id="'radio-'+kt" name="type" class="radio-style" 
														:value="t.slug" v-model="filter.type"
		                                                type="radio">
		                                                <label :for="'radio-'+kt" class="radio-style-3-label">{{t.name}}</label>
		                                            </div>
		                                        </div>
		                                        <div class="col_2">
		                                            <span>({{t.mpc_count}})</span>
		                                        </div>
		                                    </li>
	                                    	<div class="clearfix"></div>
	                                	</element>
	                                    <div class="clearfix"></div>
	                                </ul>	                                
	                            </aside>
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_top_varietal}} <a class="expand" data-toggle="collapse" href="#show_varietals" role="button"
	                                    aria-expanded="false" aria-controls="show_varietals"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="show_varietals">
	                                	<li>
	                                        <div class="col_10">
	                                            <div>
	                                                <input id="'varietal-0'" name="varietal" class="radio-style" value="" checked="" v-model="filter.varietal"
	                                                type="radio">
	                                                <label for="'varietal-0'" class="radio-style-3-label">All</label>
	                                            </div>
	                                        </div>
	                                    </li>
                                    	<div class="clearfix"></div>
	                                	<element v-for="(t,kt) in topVarietal"  :key="kt">
	                                		<li>
		                                        <div class="col_10">
		                                            <div>
		                                                <input :id="'varietal-'+kt" name="varietal" class="radio-style" :value="t['mpv_id']" v-model="filter.varietal"
		                                                type="radio">
		                                                <label :for="'varietal-'+kt" class="radio-style-3-label">{{t['mpv_name_'+hl]}}</label>
		                                            </div>
		                                        </div>
		                                        <div class="col_2">
		                                            <span>({{t.mpv_count}})</span>
		                                        </div>
		                                    </li>
	                                    	<div class="clearfix"></div>
	                                	</element>
	                                    <div class="clearfix"></div>
	                                </ul>	                                
	                            </aside>
	                            <aside class="prod_category">
	                               <h3>{{web.lbl_brand}} <a class="expand"  data-toggle="collapse" href="#brand" role="button"
	                                    aria-expanded="false" aria-controls="brand"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="brand">
	                                	<element v-for="(b,kb) in brandType"  :key="kb" v-if="kb < limit_brand">
		                                    <li>
		                                        <div class="col_10" @click="doCheck('brand', b.mb_id)">
		                                            <div>
		                                                <input class="checkbox-style":checked="filter.brand.indexOf(b.mb_id.toString())>-1" type="checkbox">
		                                                <label class="checkbox-style-3-label">{{b.mb_name}}</label>
		                                            </div>
		                                        </div>
		                                        <div class="col_2">
		                                            <span>({{b.mb_count}})</span>
		                                        </div>
		                                    </li>
		                                </element>
	                                    <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_brand(default_limit, brandType.length)">{{ limit_brand===8?web.btn_show_more: web.btn_hide_more}}</h4>
	                                    <div class="clearfix"></div>
	                                </ul>
	                            </aside>
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_body}} <a class="expand"  data-toggle="collapse" href="#body" role="button"
	                                    aria-expanded="false" aria-controls="body"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="body">
	                                	<element v-for="(v,k) in bodySize" :key="k">
	                                		<li>
		                                        <div class="col_10" @click="doCheck('body', v.mbps_id)">
		                                            <div>
		                                                <input :checked="filter.body.indexOf(v.mbps_id.toString())>-1" class="checkbox-style" type="checkbox">
		                                                <label class="checkbox-style-3-label">{{v['mbps_name_'+hl]}} </label>
		                                            </div>
		                                        </div>
		                                        <div class="col_2">
		                                            <span>({{v.mbps_count}})</span>
		                                        </div>
		                                    </li>
	                                	</element>
	                                    <div class="clearfix"></div>
	                                </ul>
	                            </aside>
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_sweet_dry_scale}}<a class="expand"  data-toggle="collapse" href="#sweet" role="button"
	                                    aria-expanded="false" aria-controls="sweet"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="sweet">
										<element v-for="(vb,kb) in dryScale" :key="kb">
	                                    <li>
	                                        <div class="col_10" @click="doCheck('scale', vb.mds_id)">
	                                            <div>
	                                                <input :checked="filter.scale.indexOf(vb.mds_id.toString())>-1" class="checkbox-style" type="checkbox">
	                                                <label class="checkbox-style-3-label">{{vb['mds_name_'+hl]}}</label>
	                                            </div>
	                                        </div>
	                                        <div class="col_2">
	                                            <span>({{vb.mds_count}})</span>
	                                        </div>
	                                    </li>
	                                    </element>
	                                    <div class="clearfix"></div>
	                                </ul>
	                            </aside>
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_country}} <a class="expand"  data-toggle="collapse" href="#country" role="button"
	                                    aria-expanded="false" aria-controls="country"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="country">
										<element v-for="(c,k) in mrCountry" :key="k" v-if="k < limit_by">
	                                    <li @click="doCheck('country',c.id)">
	                                        <div class="col_10">
	                                            <div>
	                                                <input :checked="filter.country.indexOf(c.id.toString())>-1" class="checkbox-style" type="checkbox">
	                                                <label class="checkbox-style-3-label">{{c['mc_name_'+hl]}}</label>
	                                            </div>
	                                        </div>
	                                        <div class="col_2">
	                                            <span>({{c.mc_count}})</span>
	                                        </div>
	                                    </li>
	                                    </element>
	                                    <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_toggle(default_limit, mrCountry.length)">{{ limit_by===8?web.btn_show_more: web.btn_hide_more}}</h4>
	                                    <div class="clearfix"></div>
	                                </ul>
	                            </aside>
	                            <aside class="prod_category">
	                                <h3>{{web.lbl_region}} <a class="expand"  data-toggle="collapse" href="#region" role="button"
	                                    aria-expanded="false" aria-controls="region"><i
	                                class="servicedrop  icon-line-plus"></i></a></h3>
	                                <ul class="collapse show" id="region">
										<element v-for="(c,k) in mrRegion" :key="k" v-if="k < limit_region">
	                                    <li @click="doCheck('region',c.mr_id)">
	                                        <div class="col_10">
	                                            <div>
	                                                <input :checked="filter.region.indexOf(c.mr_id.toString())>-1" class="checkbox-style" type="checkbox">
	                                                <label class="checkbox-style-3-label">{{c['mr_name_'+hl]}}</label>
	                                            </div>
	                                        </div>
	                                        <div class="col_2">
	                                            <span>({{c.mr_count}})</span>
	                                        </div>
	                                    </li>
	                                    </element>
	                                    <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_region(default_limit, mrRegion.length)">{{ limit_region===8?web.btn_show_more: web.btn_hide_more}}</h4>
	                                    <div class="clearfix"></div>
	                                </ul>
	                            </aside>
	                        </div>
	                        <div class="col-md-8 col-lg-9">
	                        	<div class="floating-button d-md-none mb-3">
	                                <a href="javascript:;" class="btn_line mt-0 toggleFilter" @click="clickFilter">Filter</a>
	                            </div>
	                            <div v-if="wyneProduct.length">
		                            <div v-masonry="containerId" transition-duration="0.3s" item-selector=".item" class="row tight" v-if="isMobile">
		                                <div class="col-md-6 col-lg-4 col-6 item" v-masonry-tile  v-for="(v,k) in wyneProduct" :key="k">
		                                    <div class="wrap_prod_list">
		                                        <div class="wrap_prod_thumb">
		                                            <router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
		                                            	<img :src="uploader(v.apd_image)" :alt="v.apd_name" :mobile-img-src="uploader(v.apd_image)"/>
		                                            </router-link>
		                                            <a href="javascript:;" :id="'fav_'+v.apd_id" @click="addFavorite(v)" class="btn_fav">
		                                            	<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
		                                            	<i v-else class="icon-line-heart"></i>
		                                            </a>
		                                        </div>
		                                        <div class="prod_desc">
		                                            <h3><router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">{{v.apd_name}}</router-link></h3>
		                                            <h4>{{v.apd_year}}</h4>
		                                            <p>Rp {{v.price}}</p>
		                                            <div class="rating_prod">
		                                                <i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                            </div>
		                            <div class="row tight" v-else>
		                                <div class="col-md-6 col-lg-4 col-6"  v-for="(v,k) in wyneProduct" :key="k">
		                                    <div class="wrap_prod_list">
		                                        <div class="wrap_prod_thumb">
		                                            <router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">
		                                            	<img :src="uploader(v.apd_image)" :alt="v.apd_name" :mobile-img-src="uploader(v.apd_image)"/>
		                                            </router-link>
		                                            <a href="javascript:;" :id="'fav_'+v.apd_id" @click="addFavorite(v)" class="btn_fav">
		                                            	<i v-if="v.isFavorite=='Y'" class="icon-heart3"></i>
		                                            	<i v-else class="icon-line-heart"></i>
		                                            </a>
		                                        </div>
		                                        <div class="prod_desc">
		                                            <h3><router-link :to="{name:'DetailProduct',params:{slug:v.apd_slug}}">{{v.apd_name}}</router-link></h3>
		                                            <h4>{{v.apd_year}}</h4>
		                                            <p>Rp {{v.price}}</p>
		                                            <div class="rating_prod">
		                                                <i :class="v.apd_review_start>=1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>1 ? 'icon-line-star checked_rat' : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>2 ? (v.apd_review_start > 2 && v.apd_review_start < 3 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat')  : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>3 ? (v.apd_review_start > 3 && v.apd_review_start < 4 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
			                                            <i :class="v.apd_review_start>4 ? (v.apd_review_start > 4 && v.apd_review_start < 5 ? 'icon-line-star checked_rat half' : 'icon-line-star checked_rat') : 'icon-line-star' "></i>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                            </div>
		                            <div class="clearfix"></div>
	                                <div class="col-12 justify-content-center">
		                                <Pagination class="justify-content-center mt-5" :data="Pagination" :limit="3" @pagination-change-page="onPaging"></Pagination>
		                            </div>
		                        </div>
	                            <div class="row tight" v-else>
	                            	<div v-if="loadingProduct" class="text-center col-12">
	                            		<img :src="assets('fo_images','preloader.gif')">
	                            	</div>
	                            	<div class="col-12 text-center" v-else><h4>
	                            	{{$route.query.search ? web.no_search_result+$route.query.search : web.lbl_no_product}}
	                            	</h4></div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        
	        <div class="clear"></div>
			<div class="filter-overlay" v-if="isMobile" @click="clickFilter"></div>
	        <div id="filterMobile" v-if="isMobile">
	            <div class="text-left filter-close" @click="clickFilter">
	                <i class="icon-line-cross icon-lg toggleFilter"></i>
	            </div>
	            <div>
	                <div class="txt_sort_by">
	                    <p>{{web.lbl_sort_by}}</p>
	                </div>
	            </div>
	            <div>
	                <div class="form-group select_custom_dark">
	                   	<select v-model="filter.order" id="template-contactform-service" name="order" class="sm-form-control frm_custom_filter valid">
	                        <option value="asc">A-Z</option>
	                        <option value="desc">Z-A</option>
	                        <option value="favorite">{{web.lbl_favorite}}</option>
                    	</select>
	                </div>
	            </div>
	            <aside class="prod_category mt-3">
                    <h3>{{web.lbl_type}} <a class="expand" data-toggle="collapse" href="#show_cat" role="button"
                        aria-expanded="false" aria-controls="show_cat"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="show_cat">
                    	<li>
                            <div class="col_10">
                                <div>
                                    <input id="'radio-0'" name="type" class="radio-style" v-model="filter.type" type="radio">
                                    <label for="'radio-0'" class="radio-style-3-label">All</label>
                                </div>
                            </div>
                        </li>
                    	<div class="clearfix"></div>
                    	<element v-for="(t,kt) in wyneType"  :key="kt">
                    		<li>
                                <div class="col_10">
                                    <div>
                                        <input :id="'radio-'+kt" name="type" class="radio-style" :value="t.slug" v-model="filter.type"
                                        type="radio">
                                        <label :for="'radio-'+kt" class="radio-style-3-label">{{t.name}}</label>
                                    </div>
                                </div>
                                <div class="col_2">
                                    <span>({{t.mpc_count}})</span>
                                </div>
                            </li>
                        	<div class="clearfix"></div>
                    	</element>
                        <div class="clearfix"></div>
                    </ul>	                                
                </aside>
                <aside class="prod_category">
                    <h3>{{web.lbl_top_varietal}} <a class="expand" data-toggle="collapse" href="#show_varietals" role="button"
                        aria-expanded="false" aria-controls="show_varietals"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="show_varietals">
                    	<li>
                            <div class="col_10">
                                <div>
                                    <input id="'varietal-0'" name="varietal" class="radio-style" value="" checked="" v-model="filter.varietal"
                                    type="radio">
                                    <label for="'varietal-0'" class="radio-style-3-label">All</label>
                                </div>
                            </div>
                        </li>
                    	<div class="clearfix"></div>
                    	<element v-for="(t,kt) in topVarietal"  :key="kt">
                    		<li>
                                <div class="col_10">
                                    <div>
                                        <input :id="'varietal-'+kt" name="varietal" class="radio-style" :value="t['mpv_id']" v-model="filter.varietal"
                                        type="radio">
                                        <label :for="'varietal-'+kt" class="radio-style-3-label">{{t['mpv_name_'+hl]}}</label>
                                    </div>
                                </div>
                                <div class="col_2">
                                    <span>({{t.mpv_count}})</span>
                                </div>
                            </li>
                        	<div class="clearfix"></div>
                    	</element>
                        <div class="clearfix"></div>
                    </ul>	                                
                </aside>
                <aside class="prod_category">
                   <h3>{{web.lbl_brand}} <a class="expand"  data-toggle="collapse" href="#brand" role="button"
                        aria-expanded="false" aria-controls="brand"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="brand">
                    	<element v-for="(b,kb) in brandType"  :key="kb" v-if="kb < limit_brand">
                            <li>
                                <div class="col_10" @click="doCheck('brand', b.mb_id)">
                                    <div>
                                        <input class="checkbox-style":checked="filter.brand.indexOf(b.mb_id.toString())>-1" type="checkbox">
                                        <label class="checkbox-style-3-label">{{b.mb_name}}</label>
                                    </div>
                                </div>
                                <div class="col_2">
                                    <span>({{b.mb_count}})</span>
                                </div>
                            </li>
                        </element>
                        <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_brand(default_limit, brandType.length)">{{ limit_brand===8?web.btn_show_more: web.btn_hide_more}}</h4>
                        <div class="clearfix"></div>
                    </ul>
                </aside>
                <aside class="prod_category">
                    <h3>{{web.lbl_body}} <a class="expand"  data-toggle="collapse" href="#body" role="button"
                        aria-expanded="false" aria-controls="body"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="body">
                    	<element v-for="(v,k) in bodySize" :key="k">
                    		<li>
                                <div class="col_10" @click="doCheck('body', v.mbps_id)">
                                    <div>
                                        <input :checked="filter.body.indexOf(v.mbps_id.toString())>-1" class="checkbox-style" type="checkbox">
                                        <label class="checkbox-style-3-label">{{v['mbps_name_'+hl]}} </label>
                                    </div>
                                </div>
                                <div class="col_2">
                                    <span>({{v.mbps_count}})</span>
                                </div>
                            </li>
                    	</element>
                        <div class="clearfix"></div>
                    </ul>
                </aside>
                <aside class="prod_category">
                    <h3>{{web.lbl_sweet_dry_scale}}<a class="expand"  data-toggle="collapse" href="#sweet" role="button"
                        aria-expanded="false" aria-controls="sweet"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="sweet">
						<element v-for="(vb,kb) in dryScale" :key="kb">
                        <li>
                            <div class="col_10" @click="doCheck('scale', vb.mds_id)">
                                <div>
                                    <input :checked="filter.scale.indexOf(vb.mds_id.toString())>-1" class="checkbox-style" type="checkbox">
                                    <label class="checkbox-style-3-label">{{vb['mds_name_'+hl]}}</label>
                                </div>
                            </div>
                            <div class="col_2">
                                <span>({{vb.mds_count}})</span>
                            </div>
                        </li>
                        </element>
                        <div class="clearfix"></div>
                    </ul>
                </aside>
                <aside class="prod_category">
                    <h3>{{web.lbl_country}} <a class="expand"  data-toggle="collapse" href="#country" role="button"
                        aria-expanded="false" aria-controls="country"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="country">
						<element v-for="(c,k) in mrCountry" :key="k" v-if="k < limit_by">
                        <li @click="doCheck('country',c.id)">
                            <div class="col_10">
                                <div>
                                    <input :checked="filter.country.indexOf(c.id.toString())>-1" class="checkbox-style" type="checkbox">
                                    <label class="checkbox-style-3-label">{{c['mc_name_'+hl]}}</label>
                                </div>
                            </div>
                            <div class="col_2">
                                <span>({{c.mc_count}})</span>
                            </div>
                        </li>
                        </element>
                        <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_toggle(default_limit, mrCountry.length)">{{ limit_by===8?web.btn_show_more: web.btn_hide_more}}</h4>
                        <div class="clearfix"></div>
                    </ul>
                </aside>
                <aside class="prod_category">
                    <h3>{{web.lbl_region}} <a class="expand"  data-toggle="collapse" href="#region" role="button"
                        aria-expanded="false" aria-controls="region"><i
                    class="servicedrop  icon-line-plus"></i></a></h3>
                    <ul class="collapse show" id="region">
						<element v-for="(c,k) in mrRegion" :key="k" v-if="k < limit_region">
                        <li @click="doCheck('region',c.mr_id)">
                            <div class="col_10">
                                <div>
                                    <input :checked="filter.region.indexOf(c.mr_id.toString())>-1" class="checkbox-style" type="checkbox">
                                    <label class="checkbox-style-3-label">{{c['mr_name_'+hl]}}</label>
                                </div>
                            </div>
                            <div class="col_2">
                                <span>({{c.mr_count}})</span>
                            </div>
                        </li>
                        </element>
                        <h4 id="btn_more" class="read_more" style="cursor:pointer;" @click="simple_region(default_limit, mrRegion.length)">{{ limit_region===8?web.btn_show_more: web.btn_hide_more}}</h4>
                        <div class="clearfix"></div>
                    </ul>
                </aside>
	            <a href="javascript:;" @click="clickFilter" class="btn_line d-block text-center">{{web.btn_apply_filter}}</a>
	        </div>
	    </section>
	</div>
</template>